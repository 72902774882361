import { Fragment, useEffect, useState } from "react";
import "./Login.css";
import SlackAuth from "../components/Auth/SlackAuth";
import GoogleAuth from "../components/Auth/GoogleAuth";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

// 05/20/22 - modifying auth screens to use AUTH_MODES just in the GoogleAuth component
// but leaving it here at the top-level, just in case we need it later again
export const AUTH_MODES = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
};

export const AUTH_SCREENS = {
  SLACK: "SLACK",
  GOOGLE: "GOOGLE",
};

export const AuthHeader = () => {
  return (
    <Fragment>
      <p className="text-center">
        👋 <span className="font-basis-bold">Welcome to Haekka!</span> <br />
        Get started by Signing in.
      </p>
    </Fragment>
  );
};

const Login = () => {
  const history = useHistory();
  const [authMode, setAuthMode] = useState(AUTH_MODES.LOGIN);
  const [authScreen, setAuthScreen] = useState(AUTH_SCREENS.SLACK);
  const [error, setError] = useState(null);
  const buttonActionText =
    authMode === AUTH_MODES.LOGIN ? "Sign in with " : "Register with ";

  const location = useLocation();
  let params = queryString.parse(location.search);

  useEffect(() => {
    const { google_success } = params;
    if (google_success) setAuthScreen(AUTH_SCREENS.GOOGLE);
  }, [params]);

  const handleChangeMode = () => {
    if (authMode === AUTH_MODES.LOGIN) {
      setAuthMode(AUTH_MODES.REGISTER);
    } else {
      setAuthMode(AUTH_MODES.LOGIN);
    }
  };

  const clearError = () => {
    setError(null);
    history.push("/login");
  };

  return (
    <div className="absolute left-0 right-0 text-center hka-sign-in-element-group">
      <div className="px-1 py-6 mx-2 mb-4 text-center bg-white shadow-xl sm:px-5 sm:mx-auto rounded-xl hka-sign-in-container sm:w-44">
        {error ? (
          <Fragment>
            <h4 className="mb-2 font-basis-bold">
              🙁 Oops, there was an issue
            </h4>
            <div className="flex flex-col items-center">
              {error === "no_workspace" && (
                <p className="px-4 mb-2">
                  No workspace found associated with your account.
                </p>
              )}
              {error === "missing_permissions" && (
                <p className="px-4 mb-2">
                  Sorry about that! It looks like you don't have the required{" "}
                  <span className="font-basis-bold">Google Workspace</span>{" "}
                  permissions to register for Haekka. Please contact your Google
                  Workspace administrator.
                </p>
              )}
              {error === "generic_error" && (
                <p className="mb-2">
                  Something went wrong.{" "}
                  <a
                    href="https://www.haekka.com/contact-us"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="cursor-pointer font-basis-bold text-orange">
                      Please contact administrator
                    </span>
                  </a>
                  .
                </p>
              )}
              <p
                className="w-24 leading-5 border rounded-md cursor-pointer border-hka_gray-border"
                onClick={clearError}
              >
                👈 Try <span className="font-basis-bold">again</span>
              </p>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <img
              src="https://haekka-public-images.s3.us-west-2.amazonaws.com/hka--new-logo.svg"
              alt="Haekka Logo"
              className="h-4 mx-auto mb-4 min-w-12 sm:w-auto"
            />

            <div className="space-y-3">
              {authScreen === AUTH_SCREENS.SLACK && (
                <SlackAuth
                  action={buttonActionText}
                  setAuthScreen={setAuthScreen}
                />
              )}
              {authScreen === AUTH_SCREENS.GOOGLE && (
                <GoogleAuth
                  authMode={authMode}
                  setAuthMode={setAuthMode}
                  action={buttonActionText}
                  onError={(identifier) => setError(identifier)}
                  setAuthScreen={setAuthScreen}
                  handleChangeMode={handleChangeMode}
                />
              )}
            </div>
          </Fragment>
        )}
      </div>
      <p className="mb-1 text-sm text-center text-hka_gray">
        © DayZero, Inc • 2024 | hello@haekka.com
      </p>
      <a
        href="https://www.haekka.com"
        className="text-sm text-center hka-link"
        target="_blank"
        rel="noreferrer"
      >
        👈 Go to Haekka.com
      </a>
    </div>
  );
};

export default Login;
