import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Employees from './containers/Employees';
import Login from './containers/Login';
import Employee from './containers/Employee';
import Settings from './containers/Settings';
import Home from './containers/Home';
import Admins from './containers/Admins';
import Trainings from './containers/Trainings';
import Catalog from './containers/Catalog';
import Training from './containers/Training';
import Engagements from './containers/Engagements';
import NotFound from './containers/NotFound';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import UnauthenticatedRoute from './components/UnauthenticatedRoute';
import SlackInstallRedirect from './components/AppInstallation/SlackInstallRedirect';
import TrainingAttempt from './containers/TrainingAttempt';
import Engagement from './containers/Engagement';
import Workflows from './containers/Workflows';
import Billing from './containers/Billing';
import Phishing from './containers/Phishing';
import Streams from './containers/Streams';

import VantaSuccess from './components/Settings/VantaSuccess';
import VantaError from './components/Settings/VantaError';
import { useAppContext } from './libs/contextLib';
import PhishingCampaignDetails from './containers/PhishingCampaignDetails';
import TrainingVideoLesson from './containers/TrainingVideoLesson';
import EngagementVideoLesson from './containers/EngagementVideoLesson';
import ExternalAppInstallationsSuccess from './components/Settings/Integrations/ExternalAppInstallationsSuccess';
import ExternalAppInstallationsError from './components/Settings/Integrations/ExternalAppInstallationsError';

export default function Routes() {
  const { featureFlags } = useAppContext();

  return (
    <Switch>
      <AuthenticatedRoute exact path='/'>
        <Home />
      </AuthenticatedRoute>
      <SlackInstallRedirect exact path='/auth/redirect/slack' />
      <UnauthenticatedRoute exact path='/login'>
        <Login />
      </UnauthenticatedRoute>
      <AuthenticatedRoute exact path='/settings'>
        <Settings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/trainings'>
        <Trainings />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/engagements' adminsOnly>
        <Engagements />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/engagements/:id' adminsOnly>
        <Engagement />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/engagements/attempt/video-lesson'>
        <EngagementVideoLesson />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/catalog' adminsOnly>
        <Catalog />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/catalog/:id' adminsOnly>
        <TrainingAttempt />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/trainings/attempt/video-lesson'>
        <TrainingVideoLesson />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/trainings/attempt/:id'>
        <TrainingAttempt />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/trainings/:id' adminsOnly>
        <Training />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/admins' adminsOnly>
        <Admins />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/employees' allowManagers>
        <Employees />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/employees/:id' allowManagers>
        <Employee />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/workflows' adminsOnly>
        <Workflows />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/streams' adminsOnly>
        <Streams />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/billing' adminsOnly>
        <Billing />
      </AuthenticatedRoute>
      {featureFlags?.PHISHING && (
        <AuthenticatedRoute exact path='/phishing' adminsOnly>
          <Phishing />
        </AuthenticatedRoute>
      )}
      {featureFlags?.PHISHING && (
        <AuthenticatedRoute
          exact
          path='/phishing/:phishingCampaignId'
          adminsOnly
        >
          <PhishingCampaignDetails />
        </AuthenticatedRoute>
      )}
      <AuthenticatedRoute exact path='/vanta/success' adminsOnly>
        <VantaSuccess />
      </AuthenticatedRoute>
      <AuthenticatedRoute exact path='/vanta/error' adminsOnly>
        <VantaError />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path='/integrations/:integration/success'
        adminsOnly
      >
        <ExternalAppInstallationsSuccess />
      </AuthenticatedRoute>
      <AuthenticatedRoute
        exact
        path='/integrations/:integration/error'
        adminsOnly
      >
        <ExternalAppInstallationsError />
      </AuthenticatedRoute>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
