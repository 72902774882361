import { useHistory } from "react-router";
import { DropdownMenu, DropdownMenuItem } from "./DropdownMenu";

const AnchorHomeCmp = ({ showMenu, userName }) => {
  return (
    <div
      className={
        "flex cursor-pointer pointer-events-none sm:pointer-events-auto space-x-1 " +
        (showMenu ? "menu--expanded" : "menu--collapsed")
      }
      id="user-menu"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <p className="mr-1">{userName}</p>
      <span className="hidden icon-chevron sm:inline-block mt-0.5"></span>
    </div>
  );
};

const HomeDropDownMenu = ({ handleLogout, user, showMenu, setShowMenu }) => {
  const history = useHistory();
  const handleNavigateTo = (page) => {
    history.push(`/${page}`);
    setShowMenu(false);
  };
  return (
    <DropdownMenu
      showMenu={showMenu}
      setShowMenu={() => {
        if (showMenu) {
          setShowMenu(false);
        } else {
          setShowMenu(true);
        }
      }}
      component={<AnchorHomeCmp showMenu={showMenu} userName={user.name} />}
      menuClass="hidden sm:block"
    >
      <DropdownMenuItem
        onClick={() => handleNavigateTo("settings")}
        icon="icon-cog"
        label="Settings"
      />
      <DropdownMenuItem
        onClick={handleLogout}
        icon="icon-logout"
        label="Logout"
      />
    </DropdownMenu>
  );
};

export default HomeDropDownMenu;
