import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import ProgressBar from '@ramonak/react-progress-bar';

import { useAppContext } from '../../libs/contextLib';
import { formatEmployeeTrainingProgressLabel } from '../../utils/training';

const formatTrainingCompletenessLabel = (training) => {
  let employeesCompletedTrainingPercentage = (
    (training.employees_completed / training.employees_assigned) *
    100
  ).toFixed(0);
  if (training.employees_assigned === 0) {
    employeesCompletedTrainingPercentage = 0;
  }
  return (
    <p className='text-xs cursor-default'>
      {employeesCompletedTrainingPercentage}% ({training.employees_completed}/
      {training.employees_assigned})
    </p>
  );
};

const formatRenewCycleString = (renewCycle) => {
  switch (renewCycle) {
    case 'quarterly':
      return 'Renews quarterly.';

    case 'semi-annual':
      return 'Renews semi-annualy.';

    case 'annual':
      return 'Renews annualy.';

    default:
      return 'Does not renew.';
  }
};

const formatDueDateString = (dueDate) => {
  const dateFormat = 'MMM, d, yyyy'; // example: May, 20, 2022

  if (!dueDate) return 'No due date';

  return format(new Date(dueDate), dateFormat);
};

const calculateCompletedPercentageForAssigment = (training) => {
  const lessonsCount = training.number_of_lessons;
  const assessmentsCount = training.number_of_assessments;
  const lessonsCompleted = training.completed_lessons;
  const assessmentsCompleted = training.completed_assessments;
  const itemsTotal = lessonsCount + assessmentsCount;

  if (itemsTotal === 0) return 0;

  return ((lessonsCompleted + assessmentsCompleted) / itemsTotal) * 100;
};

const composeProgressLabel = (training) => {
  const totalLessons = training.number_of_lessons;
  const completedLessons = training.completed_lessons;
  const totalAssessments = training.number_of_assessments;
  const completedAssessments = training.completed_assessments;

  return formatEmployeeTrainingProgressLabel({
    totalLessons,
    completedLessons,
    totalAssessments,
    completedAssessments,
  });
};

export default function TrainingListItem({ training }) {
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  const isAdmin = isAuthenticated.is_admin;
  const isLoggedInUserAssigned = !!training.assigment_id;

  const navigateToTrainingAttemptPage = () => {
    if (!isLoggedInUserAssigned) return;
    history.push(`/trainings/attempt/${training.id}`);
  };

  const navigateToEditTrainingPage = () =>
    history.push(`/trainings/${training.id}`);

  return (
    <div className='relative flex flex-col p-2 mb-1 bg-white sm:justify-center sm:items-center sm:flex-row training-list-item list-item--training sm:ml-0 rounded-xl shadow-light'>
      <div className='w-full'>
        <h4
          className={`title text-base mb-0.5 font-basis-bold w-3/4 truncate ${
            isLoggedInUserAssigned ? 'link--bold cursor-pointer' : ''
          }`}
          onClick={navigateToTrainingAttemptPage}
        >
          📚 {training.title}
        </h4>
        <section className='flex flex-wrap stats text-hka_gray '>
          <div
            className='flex items-center mr-2 flex-nowrap'
            data-tip={`Total Lessons: ${training.number_of_lessons}`}
          >
            <span className='icon-lesson icon-xs lesson-counter -mt-0.3 flex items-center'></span>
            <p className='text-xs cursor-default min-w-1'>
              {training.number_of_lessons}
            </p>
            <span className='ml-0.2 sm:hidden'>
              {training.number_of_lessons === 1 ? 'Lesson' : 'Lessons'}
            </span>
          </div>

          {isAdmin && (
            <div
              className='flex items-center mr-2 flex-nowrap'
              data-tip={`Enrolled Employees: ${training.employees_assigned}`}
            >
              <span className='icon-users icon-xs -mt-0.5'></span>
              <p className='text-xs cursor-default min-w-1'>
                {training.employees_assigned}
              </p>
              <span className='ml-0.2 sm:hidden'>
                {training.employees_assigned === 1 ? 'Employee' : 'Employees'}{' '}
              </span>
            </div>
          )}

          {isAdmin && (
            <div
              className='flex items-center mr-2 flex-nowrap'
              data-tip='Employee Completion'
            >
              <span className='icon-training-completion -mt-0.5 icon-xs percentage'></span>
              {formatTrainingCompletenessLabel(training)}
            </div>
          )}

          {isLoggedInUserAssigned && (
            <div
              className='flex items-center mr-2 flex-nowrap'
              data-tip='Training Due Date'
            >
              <span className='due-date icon-xs icon-calendar--small'></span>
              <p className='text-xs cursor-default'>
                {formatDueDateString(training.due_date)}
              </p>
            </div>
          )}

          {isAdmin && (
            <div
              className='flex items-center flex-nowrap'
              data-tip='Renew Cycle'
            >
              <span className='renew-date icon-xs -mt-0.5 icon-renew'></span>
              <p className='text-xs cursor-default'>
                {formatRenewCycleString(training.renew_cycle)}
              </p>
            </div>
          )}
        </section>
      </div>

      {isLoggedInUserAssigned && (
        <section className='flex flex-col justify-between flex-none w-full mt-1 sm:mt-0 sm:items-center sm:flex-row actions sm:w-auto'>
          <div className='flex flex-col items-start w-full md:mr-4 text-hka_gray'>
            <ProgressBar
              className='progress-bar'
              completed={calculateCompletedPercentageForAssigment(training)}
              isLabelVisible={false}
              width='16rem'
              height='0.5rem'
              borderRadius='0.25rem'
              baseBgColor='#EFF0F7'
              bgColor='#F99A00'
            />

            <p className='text-xs font-basis-bold'>
              {composeProgressLabel(training)}
            </p>
          </div>
        </section>
      )}

      {isAdmin && (
        <span
          className='absolute sm:static top-2 sm:top-0 right-2 card__action icon-cog'
          onClick={navigateToEditTrainingPage}
        ></span>
      )}
    </div>
  );
}
