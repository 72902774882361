import React from "react";
import { useHistory } from "react-router-dom";

export default function EmptyMetricsStateCard({
  title,
  redirectPath,
  imageUrl,
}) {
  const history = useHistory();

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <img
        alt="Create Entities"
        src={imageUrl}
        className="mb-2 sm:mb-4 max-w-75 sm:max-w-50"
      ></img>
      <p>
        <button
          className="px-2 button button--blue"
          onClick={() => history.push(redirectPath)}
        >
          {title}
        </button>
        .
      </p>
    </div>
  );
}
