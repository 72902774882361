import { Redirect } from "react-router-dom";
import toast from "react-hot-toast";
import { useEffect } from "react";

const VantaError = () => {
  toast.error("There was a trouble with adding Vanta. Please try again later.");

  return <Redirect to="/settings?view=integrations" />;
};

export default VantaError;
