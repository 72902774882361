import { Fragment } from 'react';
import ActionButton from '../../ActionButton';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Note from "../../Note";

const ExternalAppInstallationsError = () => {
  const history = useHistory();
  const { integration } = useParams();
  const integrationCapitalized =
    integration.charAt(0).toUpperCase() + integration.slice(1);

  return (
    <Fragment>
      <section className="mb-2">
        <Note type="warning">
          <p>There was a trouble integrating with {integrationCapitalized}. Please try again or contact Haekka team.</p>
        </Note>
      </section>
      <ActionButton
        onClick={() => history.push('/settings?view=integrations')}
        type='secondary'
      >
        <span className='hidden sm:block'>Back to the Integrations page</span>
      </ActionButton>
    </Fragment>
  );
};

export default ExternalAppInstallationsError;
