import { useHistory } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createEngagementCombinedHandler } from "../../../utils/engagementsUtils";
import { onError } from "../../../libs/errorLib";

export const useCreateEngagementData = () => {
  const queryClient = useQueryClient();
  const history = useHistory();

  return useMutation({
    mutationFn: createEngagementCombinedHandler,
    onSuccess: (newCompanyEngagement) => {
      queryClient.setQueryData(
        ["engagement", newCompanyEngagement.id],
        newCompanyEngagement
      );
      history.push(`/engagements/${newCompanyEngagement.id}`);
    },
    onError: (_error) => onError(_error),
  });
};
