import React from "react";
import { useHistory } from "react-router-dom";

const Back = ({ caption, push = false }) => {
  const history = useHistory();

  return (
    <button
      type="button"
      onClick={() => (push ? history.push(push) : history.goBack())}
      className="button__icon--left button button--gray bg-hka_gray-light"
    >
      <span className="icon-arrow left"></span>
      {caption}
    </button>
  );
};

export default Back;
