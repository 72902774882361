import { Fragment, useContext, useEffect, useState } from 'react';
import { useAppContext } from '../../../libs/contextLib';

import { useGlobalStore } from '../../../store';
import AssignEmployeesContext, {
  OBJECT_TYPES,
} from '../../../store/assign-employeees-context';
import {
  useCompanyStreamData,
  useStreamsData,
} from '../../../hooks/data-hooks/streams';

import StreamExtendedArea from './StreamExtendedArea';
import StreamActionsMenu from './StreamActionsMenu';
import TranistionWrapper from '../..//Base/TransitionWrapper';
import Loader from '../../Loader';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { streamModalViews } from '../SubcribeEmployees/SubscribeEmployeesModal';

const Stream = ({ stream, currentPage, initalStateExpanded }) => {
  const { isAuthenticated } = useAppContext();
  const location = useLocation();

  const { data: streamsPageData, isLoading: loadingStreams } = useStreamsData({
    page: currentPage,
  });

  const {
    setStreamModal,
    setDeleteStreamModal,
    setSubscribeToStreamModal,
    setManageOwnersModal,
  } = useGlobalStore((state) => state.streams);
  const [expanded, setExpanded] = useState(initalStateExpanded);

  const { data: companyStream, isLoading: isLoadingCompanyStream } =
    useCompanyStreamData({
      companyID: isAuthenticated.company,
      streamID: stream.id,
    });

  const companySubscribed = !!companyStream && companyStream.is_active;

  const {
    setObject,
    setObjectType,
    setSlackAutoEnroll,
    setModalOpened,
    setInternalModalTab,
  } = useContext(AssignEmployeesContext);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const reviewEmployeesParam = urlParams.get('review_assigned_users');
    const streamFound = streamsPageData?.results.find(
      (st) => st.id === reviewEmployeesParam
    );
    const isThisStreamSelected = streamFound?.id === stream?.id;
    if (isThisStreamSelected && streamFound && !!companyStream) {
      setModalOpened(true);
      setObject({
        ...streamFound,
        companyStreamID: companyStream.id,
      });
      setObjectType(OBJECT_TYPES.STREAM);
      setSlackAutoEnroll(companyStream.auto_enroll);
      setInternalModalTab(streamModalViews.ASSIGNED_EMPLOYEES);
    }
  }, [
    companyStream,
    location.search,
    setInternalModalTab,
    setModalOpened,
    setObject,
    setObjectType,
    setSlackAutoEnroll,
    stream,
    streamsPageData,
  ]);

  const openSubscribeUsersModalHandler = () => {
    setModalOpened(true);
    setObject({ ...stream, companyStreamID: companyStream?.id });
    setObjectType(OBJECT_TYPES.STREAM);
    setSlackAutoEnroll(companyStream?.auto_enroll);
  };

  const manageStreamOwnersHandler = () => {
    setManageOwnersModal({ show: true, stream });
  };

  if (loadingStreams || isLoadingCompanyStream)
    return (
      <TranistionWrapper show={true}>
        <Loader fullscreen />
      </TranistionWrapper>
    );

  return (
    <Fragment>
      <div
        className={`stream flex flex-col bg-white sm:justify-center sm:items-center training-list-item list-item--training sm:ml-0 rounded-xl shadow-light ${
          expanded ? 'is-expanded' : 'is-collapsed'
        }`}
      >
        <div
          className='flex items-center justify-between w-full p-2 stream__top-container'
          onClick={() => setExpanded((p) => !p)}
        >
          <div className='flex items-center'>
            <img
              src={stream.icon_url}
              alt=''
              className='w-3 h-3 mr-1 rounded-md'
            />
            <h4 className='stream__title text-base2 font-basis-medium'>
              {stream.title}
            </h4>
          </div>
          <div onClick={(e) => e.stopPropagation()}>
            {!isAuthenticated.company_details.is_global_content_maker &&
              !companySubscribed && (
                <button
                  type='button'
                  disabled={
                    !isAuthenticated.company_details.has_paid_streams &&
                    !stream.is_free &&
                    !isAuthenticated.company_details.is_global_content_maker
                  }
                  onClick={() =>
                    setSubscribeToStreamModal({
                      show: true,
                      mode: 'SUBSCRIBE',
                      stream,
                    })
                  }
                  className='button--blue button'
                >
                  <span className='block icon-plus text-hka_blue sm:hidden'></span>
                  <span className='hidden sm:inline-block'>Subscribe</span>
                </button>
              )}
            {companySubscribed && (
              <span>
                <StreamActionsMenu
                  companySubscribed={companyStream.is_active}
                  globalContentMaker={
                    isAuthenticated.company_details.is_global_content_maker
                  }
                  openSubscribeUsersModalHandler={
                    openSubscribeUsersModalHandler
                  }
                  editStreamHandler={() =>
                    setStreamModal({
                      show: true,
                      mode: 'EDIT_STREAM',
                      currentPage,
                      stream,
                    })
                  }
                  deleteStreamModalHandler={() =>
                    setDeleteStreamModal({ show: true, stream, currentPage })
                  }
                  unsubscribeFromStreamHandler={() =>
                    setSubscribeToStreamModal({
                      show: true,
                      mode: 'UNSUBSCRIBE',
                      stream,
                    })
                  }
                  setEngagementSettingsModalOpened={true}
                  manageStreamOwnersHandler={manageStreamOwnersHandler}
                />
              </span>
            )}
          </div>
        </div>

        {expanded && (
          <StreamExtendedArea stream={stream} companyStream={companyStream} />
        )}
      </div>
    </Fragment>
  );
};

export default Stream;
