import { useEffect, useRef } from "react";
import PropTypes from "prop-types";

export const DropDownAnchor = ({ showMenu }) => {
  return (
    <div
      className={
        "flex self-end space-x-1 " +
        (showMenu ? "menu--expanded" : "menu--collapsed")
      }
      id="user-menu"
      aria-expanded="false"
      aria-haspopup="true"
    >
      <button className="p-0 w-2.4 min-w-0 button--gray bg-hka_gray-light">
        <span className="icon-menu-2"></span>
      </button>
    </div>
  );
};

export const DropdownMenuItem = ({
  label,
  icon,
  onClick,
  disabled = false,
}) => {
  const itemClickedHandler = () => {
    if (!disabled) {
      onClick();
    }
  };

  return (
    <li
      className={`transition-all ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } hover:bg-hka_blue-light`}
      onClick={itemClickedHandler}
      disabled={disabled}
    >
      <div className="flex items-center flex-1">
        <span
          className={`${icon} ml-1 ${
            disabled ? "text-gray-300" : "text-hka_gray"
          }`}
        ></span>
        <p className={`ml-1.7 ${disabled ? "text-gray-300" : "text-hka_gray"}`}>
          {label}
        </p>
      </div>
    </li>
  );
};

DropdownMenuItem.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export const DropdownMenu = ({
  children,
  icon,
  component,
  showMenu,
  setShowMenu,
  menuClass,
  position = "right",
  hasBackground = true,
}) => {
  let menuRef = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleShowMenu = () => {
    setShowMenu((s) => !s);
  };

  return (
    <span className="relative flex dropdown" ref={menuRef}>
      {icon && (
        <button
          className={`rounded-lg menu w-2.4 p-0 m-0 min-w-0 cursor-pointer sm:pointer-events-auto ${
            hasBackground && "bg-hka_gray-light hover:bg-gray-200"
          }`}
          onClick={handleShowMenu}
        >
          <span className={`${icon} cursor-pointer`}></span>
        </button>
      )}
      {component && <div onClick={handleShowMenu}>{!icon && component}</div>}
      {showMenu && (
        <ul
          className={`${menuClass} absolute top-full ${position}-0 py-0.5 z-30 w-26 mt-0.5 bg-white rounded-xl shadow-lg`}
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          {children}
        </ul>
      )}
    </span>
  );
};

DropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  icon: PropTypes.string,
  component: PropTypes.node,
  showMenu: PropTypes.bool.isRequired,
  setShowMenu: PropTypes.func.isRequired,
  menuClass: PropTypes.string,
  position: PropTypes.string,
};

// absolute right-0 w-f z-30 w-24 mb-4 mt-0.5 bg-white border rounded-lg shadow-xl border-hka_gray-light sm:visible
