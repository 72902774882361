import { Redirect } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useState } from 'react';
import { useEffect } from 'react';

const ExternalAppInstallationsSuccess = () => {
  const { integration } = useParams();
  const integrationCapitalized =
    integration.charAt(0).toUpperCase() + integration.slice(1);

  const [tostingDone, setToastingDone] = useState(false);

  useEffect(() => {
    if (integration) {
      toast.success(`${integrationCapitalized} integration added successfully`);
      setToastingDone(true);
    }
  }, [integration, integrationCapitalized]);

  if (!tostingDone) return null;

  return <Redirect to='/settings?view=integrations' />;
};

export default ExternalAppInstallationsSuccess;
