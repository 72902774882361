import { useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import toast from "react-hot-toast";

import {
  useDeleteEmployee,
  useEmployeeDetailsData,
} from "../hooks/data-hooks/employees";
import { useAppContext } from "../libs/contextLib";

import EditRolesModal from "../components/EditRolesModal";
import Loader from "../components/Loader";
import DeleteEmployeeModal from "../components/Employees/DeleteEmployeeModal";
import EmployeeHeader from "../components/Employees/EmployeeHeader";
import ListEmployeeTrainingsSection from "../components/Employees/ListEmployeeTrainingsSection";
import ListEmployeeEngagementsSection from "../components/Employees/ListEmployeeEngagementsSection";

const staleTime = 30000;
const pageSize = 5;

export default function Employee() {
  const { id } = useParams();
  const { isAuthenticated } = useAppContext();
  const history = useHistory();

  const {
    data: employeeDetails,
    isError: loadingEmployeeDetailsError,
    isLoading: isLoadingEmployeeDetails,
  } = useEmployeeDetailsData({
    employeeID: id,
    staleTime,
  });

  if (loadingEmployeeDetailsError) {
    history.push("/employees");
  }

  const [showEditRolesModal, setShowEditRolesModal] = useState(false);
  const [showDeleteEmployeeModal, setShowDeleteEmployeeModal] = useState(false);
  const [roleTitle, setRoleTitle] = useState(
    employeeDetails?.role_serialized?.title
  );

  useEffect(() => {
    setRoleTitle(employeeDetails?.role_serialized?.title);
  }, [employeeDetails]);

  const onDeleteSuccess = () => {
    toast.success("Employee deleted");
    history.push("/employees");
  };

  const { mutate: deleteEmployee } = useDeleteEmployee({
    employeeID: id,
    onSuccess: onDeleteSuccess,
  });

  const deleteEmployeeHandler = () => {
    deleteEmployee({ companyID: isAuthenticated.company, employeeID: id });
  };

  if (isLoadingEmployeeDetails) {
    return <Loader />;
  }

  return (
    <Fragment>
      <EmployeeHeader
        employeeDetails={employeeDetails}
        roleTitle={roleTitle ?? "No role selected yet"}
        setShowDeleteEmployeeModal={setShowDeleteEmployeeModal}
        setShowEditRolesModal={setShowEditRolesModal}
      />

      <ListEmployeeTrainingsSection
        employeeID={id}
        pageSize={pageSize}
        staleTime={staleTime}
      />

      <ListEmployeeEngagementsSection
        employeeID={id}
        pageSize={pageSize}
        staleTime={staleTime}
      />

      <EditRolesModal
        showModal={showEditRolesModal}
        setRoleTitle={setRoleTitle}
        isAuthenticated={isAuthenticated}
        employee={employeeDetails}
        onModalHide={() => setShowEditRolesModal(false)}
      />
      <DeleteEmployeeModal
        showModal={showDeleteEmployeeModal}
        onSubmit={deleteEmployeeHandler}
        onClose={() => setShowDeleteEmployeeModal(false)}
      />
    </Fragment>
  );
}
