import { Redirect } from "react-router-dom";
import toast from "react-hot-toast";
import { useEffect } from "react";

const VantaSuccess = () => {
  toast.success("Vanta integration added successfully");

  return <Redirect to="/settings?view=integrations" />;
};

export default VantaSuccess;
