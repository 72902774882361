import React, { useEffect, useState, useRef } from "react";
import HomeDropDownMenu from "./HomeDropDownMenu";

function UserNavItem({ user, onLogout }) {
  const [showMenu, setShowMenu] = useState(false);

  let menuRef = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleLogout = (e) => {
    if (e) {
      e.preventDefault();
    }
    onLogout();
  };

  return (
    <div
      className="relative flex items-center flex-grow sm:flex-grow-0"
      ref={menuRef}
    >
      {/* <p className="hidden sm:flex whitespace-nowrap">
        {user.company_details.title}
      </p> */}
      <img
        className="mr-1 user-img"
        src={
          user.avatar_image
            ? user.avatar_image
            : "https://i2.wp.com/a.slack-edge.com/df10d/img/avatars/ava_0001-192.png"
        }
        alt=""
      />
      <HomeDropDownMenu
        handleLogout={handleLogout}
        user={user}
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      />
    </div>
  );
}

export default UserNavItem;
