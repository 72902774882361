import * as api from "../api";
import toast from "react-hot-toast";
import { onError } from "../libs/errorLib";
import { useAppContext } from "../libs/contextLib";
import { useState } from "react";
import { DropdownMenu, DropdownMenuItem } from "./DropdownMenu";
import ConvertToCustomTrainingModal from "./Training/ConvertToCustomTrainingModal";
import { useDispatch } from "react-redux";
import { trainingEvidenceActions } from "../store/trainingEvidenceSlice";
import { getTrainingEvidenceData } from "../store/trainingEvidenceActions";
import { reportEvidenceSuccessMessage } from "../utils/reportEvidenceMessages";

const TrainingDropdownMenu = (props) => {
  const { isAuthenticated } = useAppContext();
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const [convertToCustomConfirmModal, setConvertToCustomConfirmModal] =
    useState(false);
  const [submitting, setSubmitting] = useState(false);

  const downloadEvidenceHandler = async () => {
    if (props.training.renew_cycle === "none") {
      try {
        setShowMenu(false);
        dispatch(
          getTrainingEvidenceData({
            auth: isAuthenticated,
            trainingID: props.training.id,
          })
        );
        toast.success(reportEvidenceSuccessMessage);
      } catch (e) {
        onError(e);
      }
    } else {
      dispatch(trainingEvidenceActions.setModalVisibility({ isOpened: true }));
      handleShowMenu(false);
    }
  };

  const handleShowMenu = () => {
    setShowMenu((s) => !s);
  };

  const handleAddLesson = () => {
    props.onHandleAddLesson();
    handleShowMenu(false);
  };

  const handleConvertToCustom = () => {
    setConvertToCustomConfirmModal(true);
    setShowMenu(false);
  };

  const onConvertToCustom = async () => {
    try {
      setSubmitting(true);
      const training = await api.convertToCustom({
        auth: isAuthenticated,
        createData: {
          curriculum: props.training.curriculum_detail.id,
          curriculum_title: props.training.curriculum_detail.title,
          // curriculumType:
          //   props.training.curriculum_detail.curriculum_type_details.id,
          annual: props.training.annual,
          renew_cycle: props.training.renew_cycle,
          passing_grade: props.training.passing_grade,
          has_weekly_quiz: props.training.has_weekly_quiz,
          days_till_due: props.training.days_till_due,
        },
        training_id: props.training.id,
      });
      props.setTraining(training);
      props.setTriggerGetTraining((v) => !v);
      setConvertToCustomConfirmModal(false);
      toast.success(`Training converted to custom! Start editing!`);
    } catch (e) {
      onError(e);
    }
    setSubmitting(false);
  };

  const createGlobalTraining = async () => {
    try {
      await api.haekkaPublishTraining({
        auth: isAuthenticated,
        trainingId: props.training.id,
      });
      setShowMenu(false);
      props.setTriggerGetTraining((p) => !p);
    } catch (error) {
      onError(error);
    }
  };
  return (
    <>
      <ConvertToCustomTrainingModal
        showModal={convertToCustomConfirmModal}
        submitting={submitting}
        onSubmit={() => onConvertToCustom()}
        onClose={() => setConvertToCustomConfirmModal(false)}
      />
      <DropdownMenu
        icon="icon-menu-2"
        showMenu={showMenu}
        setShowMenu={setShowMenu}
      >
        {props.displayAddLessonButton && (
          <>
            <DropdownMenuItem
              onClick={handleAddLesson}
              icon="icon-add-lesson"
              label="Add Lesson"
            />
          </>
        )}
        <DropdownMenuItem
          onClick={() => {
            props.setModalOpened(true);
            setShowMenu(false);
          }}
          icon="icon-add-users"
          label="Assign Employees"
        />
        <DropdownMenuItem
          onClick={() => {
            props.onSetEditTrainingModalShow(true);
            setShowMenu(false);
          }}
          icon="icon-cog"
          label="Training Settings"
        />
        <DropdownMenuItem
          onClick={downloadEvidenceHandler}
          icon="icon-download"
          label="Download Evidence"
        />
        {!props.isCustomTraining && (
          <DropdownMenuItem
            onClick={handleConvertToCustom}
            icon="icon-convert"
            label="Convert to Custom"
          />
        )}
        {isAuthenticated.company_details.is_global_content_maker && (
          <>
            <DropdownMenuItem
              onClick={() => {
                createGlobalTraining();
                toast.success("Global Curriculum created/updated");
                props.setTriggerGetTraining((p) => !p);
              }}
              icon="icon-plus"
              label={`${
                props.globalCurriculum ? "Update" : "Create"
              } Global Curriculum`}
            />
            <DropdownMenuItem
              onClick={() => {
                props.setIsGlobalCurriculumSettingsModalOpened(true);
                setShowMenu(false);
              }}
              icon="icon-cog"
              label="Global Curriculum Settings"
            />
          </>
        )}
      </DropdownMenu>
    </>
  );
};

export default TrainingDropdownMenu;
