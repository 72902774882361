import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAppContext } from "../libs/contextLib";

export default function AuthenticatedRoute({ children, ...rest }) {
  const { pathname, search } = useLocation();
  const { isAuthenticated } = useAppContext();

  let permitted = false;

  if (rest.adminsOnly) {
    permitted = isAuthenticated?.is_admin;
  } else if (rest.allowManagers) {
    permitted = isAuthenticated?.is_admin || isAuthenticated?.is_manager;
  } else {
    permitted = !!isAuthenticated;
  }

  const redirectUri =
    rest.adminsOnly || rest.allowManagers
      ? "/"
      : `/login?redirect=${pathname}${search}`;

  return (
    <Route {...rest}>
      {permitted ? children : <Redirect to={redirectUri} />}
    </Route>
  );
}
