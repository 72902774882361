import * as api from '../api';
import { getEmployeeDetails } from '../api/employeesApi';

export const getLoggedInUserDetails = async ({ auth }) => {
  return await getEmployeeDetails({
    employeeID: auth.id,
  });
};

export const expanderViewModes = {
  TILE_MODE: 'TILE_MODE',
  LIST_MODE: 'LIST_MODE',
};

export const randomString = (length) => {
  return new Array(length).join().replace(/(.|$)/g, function () {
    return ((Math.random() * 36) | 0).toString(36);
  });
};

export const modalModes = {
  ADD: 'ADD',
  EDIT: 'EDIT',
};

export const reorderList = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const normilizeVideoURL = (url) => {
  if (url.startsWith('https://www.youtube.com/')) {
    url = url.replace('/watch?v=', '/embed/').replace('&t=', '?start=');
  }
  if (url.startsWith('https://youtu.be/')) {
    url = url
      .replace('https://youtu.be/', 'https://www.youtube.com/embed/')
      .replace('?t=', '?start=');
  }
  return url;
};

// TO-DO: implement serving emojis as a static content
// check it's usage
export const loadEmojis = async ({ auth }) => {
  const emojis = await api.getEmojisJsonMapper({
    auth: auth,
    file: 'emojis.json',
  });
  localStorage.setItem('haekka-emojis', emojis);

  const emojis_2 = await api.getEmojisJsonMapper({
    auth: auth,
    file: 'emojis-2.json',
  });
  localStorage.setItem('haekka-emojis-2', emojis_2);
};

export const readFileNameFromHeader = (disposition) => {
  let filename = 'file';
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

export const getNextMessagePosition = (array) => {
  if (array.length === 0) return 0;
  return (
    array.reduce((accumulator, item) => {
      if (item.position > accumulator) return item.position;
      return accumulator;
    }, 0) + 1
  );
};

// TO-DO: refactor the entire emoji logic
export const emojisToHtmlRepresentation = (emojis_text, emojis) => {
  if (emojis.length === 0) return emojis_text;

  const fetchedEmojiJson2 = JSON.parse(localStorage.getItem('haekka-emojis-2'));

  emojis.forEach((e) => {
    const found = fetchedEmojiJson2.find(
      (em) => em.short_name === e.substring(1, e.length - 1)
    );

    if (found) {
      const unified = found.unified.includes('-')
        ? found.unified.substring(0, found.unified.indexOf('-'))
        : found.unified;

      emojis_text = emojis_text.replace(
        e,
        String.fromCodePoint('0x' + unified)
      );
    } else {
      const fetchedEmojiJson = JSON.parse(
        localStorage.getItem('haekka-emojis')
      );
      const found2 = fetchedEmojiJson.find(
        (em) => em.name === e.substring(1, e.length - 1)
      );
      if (found2) {
        const unified = found2.unified.includes('-')
          ? found2.unified.substring(0, found2.unified.indexOf('-'))
          : found2.unified;

        emojis_text = emojis_text.replace(
          e,
          String.fromCodePoint('0x' + unified)
        );
      } else {
        emojis_text = emojis_text.replace(e, '');
      }
    }
  });

  return emojis_text;
};

export const removeUrlParam = ({ location, history, param }) => {
  const queryParams = new URLSearchParams(location.search);
  queryParams.delete(param);
  history.replace({
    search: queryParams.toString(),
  });
};

export const isCorrectOptions = [
  {
    value: 'correct',
    label: 'Correct',
    correct: true,
    isDisabled: false,
  },
  {
    value: 'incorrect',
    label: 'Incorrect',
    correct: false,
    isDisabled: false,
  },
];

export const formatOptionLabel = ({ value, label, isDisabled }) => (
  <div className='flex flex-row items-center'>
    <div>
      {value === 'correct' && (
        <span
          className={`icon-check w-2.4 h-2.4 rounded-md ${
            isDisabled
              ? 'text-hka_gray-light bg-hka_gray-ultraLight'
              : 'text-hka_green bg-hka_green-light'
          } flex items-center justify-center`}
        ></span>
      )}
      {value === 'incorrect' && (
        <span
          className={`icon-close w-2.4 h-2.4 rounded-md ${
            isDisabled
              ? 'text-hka_gray-light bg-hka_gray-ultraLight'
              : 'text-hka_red bg-hka_red-light'
          } flex items-center justify-center`}
        ></span>
      )}
    </div>
    <span
      className={`dropdown__label ${
        isDisabled ? 'text-hka_gray opacity-50' : ''
      }`}
    >
      {label}
    </span>
  </div>
);

export const shuffleArray = (array) => {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
};

export const clearParams = ({ history }) => {
  history.replace({ search: '' });
};
