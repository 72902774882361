import React, { Fragment } from "react";
import PhishingCampaingCardAssignedEmployeesSection from "./PhishingCampaingCardAssignedEmployeesSection";
import PhishingCampaignStatusIndicator from "./PhishingCampaignStatusIndicator";
import Divider from "../Base/Divider";
import Slider from "../Base/Slider";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { getCampaignStatus } from "../../utils/phishingUtils";
import { editPhishingCampaign } from "../../api";
import { onError } from "../../libs/errorLib";
import { getSendOptionDescription } from "../../utils/phishingUtils";

export default function PhishingCampaignCard({
  auth,
  phishingCampaign,
  setSelectedCampaign,
  setShowDeleteCampaignModal,
  setShowEditCampaignModal,
  setTriggerReload,
}) {
  const SLICE_SIZE = 5;

  const history = useHistory();
  const campaignStatus = getCampaignStatus(phishingCampaign);
  const sendOptionDescription = getSendOptionDescription(
    phishingCampaign,
    true
  );

  const templates =
    phishingCampaign.templates.length <= SLICE_SIZE
      ? phishingCampaign.templates
      : phishingCampaign.templates.slice(-SLICE_SIZE);

  const onDeleteCampaignButtonClick = () => {
    setSelectedCampaign(phishingCampaign);
    setShowDeleteCampaignModal(true);
  };

  const onEditCampaignButtonClick = () => {
    setSelectedCampaign(phishingCampaign);
    setShowEditCampaignModal(true);
  };

  const onEnableCampaignSliderClick = async (active) => {
    try {
      await editPhishingCampaign({
        auth,
        campaignId: phishingCampaign.id,
        payload: {
          active,
        },
      });

      setTriggerReload((v) => !v);
    } catch (error) {
      onError(error);
    }
  };

  return (
    <div className="card">
      <section className="card__header">
        <PhishingCampaignStatusIndicator campaign={phishingCampaign} />
        <div className="card__actions">
          {/* <span
            className="card__action icon-cog"
            data-tip="Edit Campaign"
            onClick={onEditCampaignButtonClick}
          ></span> */}
          <span
            className="card__action icon-trash"
            data-tip="Delete Campaign"
            onClick={onDeleteCampaignButtonClick}
          ></span>
        </div>
      </section>
      {/* Campaign Title / Last Attack Sent */}
      <section>
        <h4
          className="card__title text-base2 font-basis-medium link line-clamp-1 mb-0.4"
          onClick={() => history.push(`/phishing/${phishingCampaign.id}`)}
        >
          {phishingCampaign.name}
        </h4>

        <div className="flex items-center truncate">
          {phishingCampaign.last_sent_date ? ( 
            <>
              <span className="icon-calendar opacity-80 -mt-0.1 -ml-0.4"></span>
              <p className="text-sm ml-0.5 truncate">Last attack sent {format(new Date(phishingCampaign.last_sent_date), "MMM. dd, yyyy")}</p>
            </>
            ) : (
            <>
              <span className="icon-calendar -mt-0.1 -ml-0.4"></span>
              <p className="text-sm ml-0.5 text-hka_gray-dark truncate">Has not sent</p>
            </>
          )}
        </div>

        {sendOptionDescription && (
          <div className="flex items-center truncate">
            
              <span className="-ml-0.4 icon-renew opacity-60 -mt-0.3"></span>
              <p className="text-sm ml-0.5">Sends <span className="lowercase">{sendOptionDescription}</span></p>
            
            {/* <p className="text-sm font-basis-medium ml-0.5 text-hka_gray-dark truncate lowercase">
              {sendOptionDescription}
            </p> */}
          </div>
        )}
      </section>
      {/* Campaign Templates */}
      <section>
        <p className="text-sm font-basis-medium mb-0.5">Template(s)</p>
        <div
          className={`card__item-group${
            templates.length > 0 ? " pl-0.5" : ""
          }`}
        >
          {templates.length > 0 ? (
            templates.reverse().map((template) => (
              <div
                className="card__item--template"
                key={template.id}
                data-tip={template.name}
              >
                <img
                  src={
                    template.icon ||
                    "https://haekka-author-icons.s3.us-west-2.amazonaws.com/HKA--UPDATED--Author_Haekka.png"
                  }
                  alt={template.name}
                  className=""
                />
              </div>
            ))
          ) : (
            <p className="text-sm opacity-75">No templates selected</p>
          )}
          {phishingCampaign.templates.length > templates.length && (
            <p className="ml-1">
              {" "}
              +{phishingCampaign.templates.length - SLICE_SIZE}
            </p>
          )}
        </div>
      </section>
      {/* Assigned Employees */}
      <section>
        <PhishingCampaingCardAssignedEmployeesSection
          auth={auth}
          phishingCampaign={phishingCampaign}
        />
      </section>
      {/* Resume/Pause toggle */}
      <section>
        {campaignStatus.value !== "Unpublished" &&
          phishingCampaign.send_option !== "once_immediately" && (
            <Fragment>
              <Divider />
              <div className="flex mt-1.5 items-center justify-between">
              <p className="font-basis-medium">Enabled</p>
                <Slider
                  checked={phishingCampaign.active}
                  onChange={(e) =>
                    onEnableCampaignSliderClick(e.target.checked)
                  }
                />
              </div>
            </Fragment>
          )}
      </section>
    </div>
  );
}
