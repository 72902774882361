import { Fragment, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import * as api from '../api';
import {
  prefetchChannelEngagentResults,
  prefetchEmployeeResults,
  useCheckDynamicDistributionData,
  useCheckEngagementData,
  useCompanyEngagementData,
  useEngagementSchedulesData,
  useEngagmentStatisticsData,
  useSendEngagementNowMutation,
} from '../hooks/data-hooks/engagements';
import { useAppContext } from '../libs/contextLib';
import AssignEmployeesContext, {
  OBJECT_TYPES,
} from '../store/assign-employeees-context';

import {
  employeeViewTabs,
  formatSendCycleMessage,
} from '../utils/engagementsUtils';
import { removeUrlParam } from '../utils/commonUtils';
import Back from '../components/Back';
import TransitionWrapper from '../components/Base/TransitionWrapper';
import Loader from '../components/Loader';
import AssignEmployeesEngagements from '../components/AssignEmployees/AssignEmployeesEngagements';
import EngagementOverviewReportsModal from '../components/Engagements/EngagementOverviewReportsModal';
import EngagementEvidenceModal from '../components/Engagements/EngagementEvidenceModal';
import EngagementModal from '../components/Engagements/EngagementModal/EngagementModal';
import Tabber, { TabPanel } from '../components/Base/Tabber';
import EngagementMessageModal from '../components/Engagements/EngagementMessageModal/EngagementMessageModal';
import ListEngagementsMessages from '../components/Engagements/ListEngagementsMessages/ListEngagementsMessages';
import EmployeeEngagementsTable from '../components/Engagements/UsersResults/EmployeeEngagementsTable';
import DropdownMenu from '../components/Base/DropdownMenu';
import ScheduleDate from '../components/Engagements/ScheduleDate';
import ConfirmSendNowModal from '../components/Engagements/confirmationModals/ConfirmSendNowModal';
import EmployeeAnonymousResultsTable from '../components/Engagements/UsersResults/EmployeeAnonymousResultsTable';

const Engagement = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const { isAuthenticated } = useAppContext();
  const history = useHistory();
  const location = useLocation();
  let params = queryString.parse(location.search);

  const [
    showEngagementOverviewReportsModal,
    setShowEngagementOverviewReportsModal,
  ] = useState(false);
  const [showEngagementEvidenceModal, setShowEngagementEvidenceModal] =
    useState(false);
  const [showAddMessageModal, setShowAddMessageModal] = useState(undefined);
  const [engagementSettingsModalOpened, setEngagementSettingsModalOpened] =
    useState(false);
  const [isAssignEmplyeesDisabled, setIsAssignEmplyeesDisabled] =
    useState(true);
  const [isSendNowDisabled, setIsSendNowDisabled] = useState(false);
  const [showSendNowModal, setShowSendNowModal] = useState(false);
  const [isAddMessageBtnDisabled, setIsAddMessageBtnDisabled] = useState(false);
  const [isReadOnly, setIsReadOnly] = useState(false);

  const { isLoading, data: companyEngagement } = useCompanyEngagementData({
    id,
  });
  const isAnonymousEngagement =
    !!companyEngagement?.quiz.anonymize_user_results;
  const { isLoading: isLoadingStatistics, data: engagmentStatistics } =
    useEngagmentStatisticsData({ id });
  const { data: schedules } = useEngagementSchedulesData({
    companyEngagementID: id,
    transformData: false,
  });

  const activeSchedule = schedules?.find((s) => !s.is_sent);
  const { mutate: sendNow } = useSendEngagementNowMutation({
    enabled: !!activeSchedule,
    companyEngagementID: id,
  });

  const { data: dynamicDustributionData } = useCheckDynamicDistributionData({
    companyEngagementID: id,
    enabled:
      !!companyEngagement?.respect_users_tz &&
      companyEngagement?.quiz.engagement_type === 'private',
  });

  const { data: engagementData } = useCheckEngagementData({
    companyEngagementID: id,
    enabled:
      companyEngagement?.send_cycle === 'once' &&
      !companyEngagement?.respect_users_tz &&
      companyEngagement?.quiz.engagement_type === 'private',
  });

  // Note: question position is also used to as an identifier for the video upload
  const nextQuestionPosition = useRef(0);

  // open setting if there is ?edit=true in the URL
  const openEngagementSettingsParam =
    params && params.edit ? Boolean(params.edit) : false;
  useEffect(() => {
    if (openEngagementSettingsParam) {
      setEngagementSettingsModalOpened(true);
    }
  }, [openEngagementSettingsParam]);

  const closeSettingsModalHandler = () => {
    setEngagementSettingsModalOpened(false);
    removeUrlParam({ location, history, param: 'edit' });
  };

  // prefetch data
  if (!isLoading && companyEngagement.quiz.engagement_type === 'private') {
    prefetchEmployeeResults(queryClient, id);
  }
  if (!isLoading && companyEngagement.quiz.engagement_type === 'channel') {
    prefetchChannelEngagentResults(queryClient, id);
  }

  useEffect(() => {
    // TO-DO: improve this
    const loadEmojis = async () => {
      const emojis = await api.getEmojisJsonMapper({
        auth: isAuthenticated,
        file: 'emojis.json',
      });
      localStorage.setItem('haekka-emojis', emojis);

      const emojis_2 = await api.getEmojisJsonMapper({
        auth: isAuthenticated,
        file: 'emojis-2.json',
      });
      localStorage.setItem('haekka-emojis-2', emojis_2);
    };
    if (
      !localStorage.getItem('haekka-emojis') ||
      !localStorage.getItem('haekka-emojis-2')
    )
      loadEmojis();
  }, [isAuthenticated]);

  // Import Users
  const assignEmployeesContext = useContext(AssignEmployeesContext);
  const {
    onImportUsers,
    setObject,
    setObjectType,
    setFetchedChannels,
    setSlackAutoEnroll,
    modalOpened,
    setModalOpened,
    channelOptions,
    setSyncOktaGroupsIDs,
  } = assignEmployeesContext;
  useEffect(() => {
    const onLoad = () => {
      setObject(companyEngagement);
      setObjectType(OBJECT_TYPES.QUIZ);
      // setModalOpened(false);
      setFetchedChannels(companyEngagement.channels);
      setSyncOktaGroupsIDs(companyEngagement.okta_groups_sync);
      setSlackAutoEnroll(companyEngagement.quiz.questions.auto_enroll);
    };

    if (!!companyEngagement) onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    companyEngagement?.id,
    setFetchedChannels,
    setModalOpened,
    setObject,
    setObjectType,
    setSlackAutoEnroll,
    setSyncOktaGroupsIDs,
  ]);

  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);
  // Import Users END

  useEffect(() => {
    if (isLoading) return;
    setIsAssignEmplyeesDisabled(
      !!dynamicDustributionData?.has_distribution_started ||
        companyEngagement.quiz.engagement_type === 'channel' ||
        companyEngagement.quiz.questions.length === 0 ||
        (companyEngagement.send_cycle === 'once' &&
          schedules &&
          schedules[0].is_sent)
    );
    setIsSendNowDisabled(
      companyEngagement.quiz.questions.length === 0 ||
        (companyEngagement.send_cycle === 'once' &&
          schedules &&
          schedules[0].is_sent) ||
        (companyEngagement?.quiz.engagement_type === 'private' &&
          (engagementData?.assigements_count ?? 0) === 0)
    );
  }, [
    companyEngagement,
    schedules,
    dynamicDustributionData,
    engagementData,
    isLoading,
  ]);

  useEffect(() => {
    const isTheSingleScheduleSent =
      companyEngagement?.send_cycle === 'once' &&
      schedules &&
      schedules[0]?.is_sent;
    setIsReadOnly(isTheSingleScheduleSent);
    if (companyEngagement?.quiz.engagement_type === 'channel') {
      setIsAddMessageBtnDisabled(companyEngagement.quiz.questions.length >= 1);
    }
    if (companyEngagement?.quiz.engagement_type === 'private') {
      const firstMessage = companyEngagement.quiz.questions[0];
      setIsAddMessageBtnDisabled(
        firstMessage?.answer_type === 'no_action' ||
          firstMessage?.answer_type === 'external_url' ||
          isTheSingleScheduleSent
      );
    }
  }, [companyEngagement, schedules]);

  if (isLoading) {
    return (
      <TransitionWrapper show={isLoading} afterLeave={() => {}}>
        <Loader fullscreen />
      </TransitionWrapper>
    );
  }

  const sendNowSubmitHandler = () => {
    sendNow({
      auth: isAuthenticated,
      scheduleID: activeSchedule?.id,
    });
    setIsReadOnly(true);
    setShowSendNowModal(false);
    setIsSendNowDisabled(true);
    setTimeout(() => {
      queryClient.refetchQueries({
        queryKey: ['company-engagement-schedules', id],
      });
      queryClient.refetchQueries({
        queryKey: ['engagement-employee-results', id],
      });
    }, 5000);
  };

  const modalClosedEventHandler = () => {
    // assigning employees is delayed to Celery in the backend
    const timeoutID = setTimeout(async () => {
      queryClient.invalidateQueries(['check-engagement-data']);
      queryClient.invalidateQueries(['engagement-employee-results']);
      queryClient.invalidateQueries(['channel-engagement-results']);
      queryClient.invalidateQueries(['engagement', id]);
      clearTimeout(timeoutID);
    }, 1500);
  };

  const dropMenuActions = [
    {
      label: 'Assign Employees',
      icon: 'icon-add-users',
      onClick: () => setModalOpened(true),
      disabled: isAssignEmplyeesDisabled,
    },
    {
      label: 'Engagement Settings',
      icon: 'icon-cog',
      onClick: () => setEngagementSettingsModalOpened(true),
    },
    {
      label: 'Download Overview Report',
      icon: 'icon-download',
      onClick: () => setShowEngagementOverviewReportsModal(true),
    },
    {
      label: 'Download Evidence',
      icon: 'icon-download',
      onClick: () => setShowEngagementEvidenceModal(true),
      disabled: isAnonymousEngagement,
    },
  ];

  if (
    companyEngagement.send_cycle === 'once' &&
    !companyEngagement.respect_users_tz
  ) {
    dropMenuActions.splice(2, 0, {
      label: 'Send Now',
      icon: 'icon-arrow right',
      onClick: () => setShowSendNowModal(true),
      disabled: isSendNowDisabled,
    });
  }

  const contentTabActions = !companyEngagement.quiz.questions.length <= 0 && (
    <button
      onClick={() => setShowAddMessageModal({ create: true })}
      type='button'
      className='px-2 button button--blue'
      disabled={isAddMessageBtnDisabled}
    >
      Add Message
    </button>
  );

  const employeesTabActions = (
    <button
      onClick={() => setModalOpened(true)}
      type='button'
      className='px-2 button button--blue bg-hka_gray-light'
      disabled={isAssignEmplyeesDisabled}
    >
      Assign Employees
    </button>
  );

  const tabberActions = [
    {
      jsx: contentTabActions,
      showOn: 'content',
    },
    {
      jsx: employeesTabActions,
      showOn: 'employees',
    },
  ];

  return (
    <Fragment>
      <header className='mb-1'>
        <section className='flex justify-between w-full mb-2'>
          <Back caption={'Engagements'} />
          <DropdownMenu actions={dropMenuActions} />
        </section>
        <section className='flex flex-col items-start justify-start space-y-1 md:items-center md:justify-between md:flex-row md:space-y-0'>
          <div>
            <h1 className='mb-1 font-basis-bold text-base2 sm:text-xl'>
              {companyEngagement.quiz.title}
            </h1>
            <div className='flex flex-col items-start lg:items-center lg:flex-row'>
              {schedules && (
                <>
                  <ScheduleDate title={''} date={schedules[0]?.send_time} />
                  <ScheduleDate
                    title={'Last Sent:'}
                    date={schedules[1]?.send_time}
                  />
                </>
              )}

              <p className='flex items-center'>
                <span className='icon-renew -mt-0.4 -ml-0.4 mr-0.2'></span>
                {formatSendCycleMessage(companyEngagement.send_cycle)}
              </p>
              <p>
                {companyEngagement.quiz.engagement_type === 'channel' && (
                  <span className='ml-0.5 text-sm'>
                    to
                    <span className='font-basis-bold ml-0.5'>
                      #
                      {
                        channelOptions.find(
                          (o) => o.value === companyEngagement.quiz.channel
                        )?.label
                      }
                    </span>
                  </span>
                )}
              </p>
            </div>
          </div>
        </section>
      </header>

      <main>
        <Tabber
          items={employeeViewTabs(isAnonymousEngagement)}
          actions={tabberActions}
        >
          <TabPanel type='content'>
            {!isLoading && companyEngagement.quiz.questions.length === 0 && (
              <Fragment>
                {companyEngagement.quiz.questions.length <= 0 && (
                  <div className='flex items-start w-full gap-2 p-2 border border-opacity-50 border-dashed rounded-lg sm:items-center border-hka_gray'>
                    <img
                      src='https://haekka-public-images.s3.us-west-2.amazonaws.com/haekka__engagement-graphic.svg'
                      alt='Engagement Icon'
                      className='w-4 min-w-4'
                    />
                    <div className='flex flex-col flex-1 sm:flex-row sm:items-center'>
                      <p className='flex-1 mb-1 sm:mb-0'>
                        This Engagement doesn't have any content yet! Click the
                        Add Message button to start creating your Engagement
                        content.
                      </p>
                      <button
                        onClick={() => setShowAddMessageModal({ create: true })}
                        type='button'
                        className='button button--blue'
                      >
                        Add Message
                      </button>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
            {!isLoading && companyEngagement.quiz.questions.length > 0 && (
              <ListEngagementsMessages
                companyEngagementID={id}
                questions={companyEngagement.quiz.questions}
                engagmentStatistics={engagmentStatistics}
                isLoadingStatistics={isLoadingStatistics}
                onQuestionEdit={(questionID) =>
                  setShowAddMessageModal({ create: false, questionID })
                }
                isReadOnly={isReadOnly}
              />
            )}
          </TabPanel>
          <TabPanel type='employees'>
            <EmployeeEngagementsTable
              isAnonymousEngagement={isAnonymousEngagement}
            />
          </TabPanel>
          {isAnonymousEngagement && (
            <TabPanel type='results'>
              <EmployeeAnonymousResultsTable />
            </TabPanel>
          )}
        </Tabber>

        {modalOpened && (
          <AssignEmployeesEngagements
            modalClosedEvent={modalClosedEventHandler}
          />
        )}
        {showAddMessageModal && (
          <EngagementMessageModal
            show={!!showAddMessageModal}
            create={!!showAddMessageModal.create}
            companyEngagement={companyEngagement}
            selectedQuestion={companyEngagement.quiz.questions.find(
              (q) => q.id === showAddMessageModal.questionID
            )}
            onClose={() => setShowAddMessageModal(undefined)}
            nextQuestionPosition={nextQuestionPosition}
            isReadOnly={isReadOnly}
          />
        )}

        {engagementSettingsModalOpened && (
          <EngagementModal
            show={engagementSettingsModalOpened}
            onClose={closeSettingsModalHandler}
            isReadOnly={isReadOnly}
          />
        )}

        <EngagementOverviewReportsModal
          show={showEngagementOverviewReportsModal}
          onCancel={() => setShowEngagementOverviewReportsModal(false)}
        />
        <EngagementEvidenceModal
          show={showEngagementEvidenceModal}
          onCancel={() => setShowEngagementEvidenceModal(false)}
        />

        {showSendNowModal && (
          <ConfirmSendNowModal
            showModal={showSendNowModal}
            activeSchedule={activeSchedule}
            onSubmit={sendNowSubmitHandler}
            onClose={() => setShowSendNowModal(false)}
          />
        )}
      </main>
    </Fragment>
  );
};

export default Engagement;
