import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { useHistory, useLocation } from "react-router-dom";
import {
  createPhishingCampaign,
  deletePhishingCampaign,
  editPhishingCampaign,
  getCompanyPhishingCampaigns,
  getPhishingTemplates,
} from "../api";
import React, { Fragment, useContext, useEffect, useState } from "react";
import queryString from "query-string";
import TransitionWrapper from "../components/Base/TransitionWrapper";
import Loader from "../components/Loader";
import Callout from "../components/Callout";
import CreatePhishingCampaignModal from "../components/Phishing/modals/CreatePhishingCampaignModal";
import toast from "react-hot-toast";
import PhishingCampaignCard from "../components/Phishing/PhishingCampaignCard";
import DeletePhishingCampaignModal from "../components/Phishing/modals/DeletePhishingCampaignModal";
import EditPhishingCampaignModal from "../components/Phishing/modals/EditPhishingCampaignModal";
import PhishingCampaignButton from "../components/Phishing/PhishingCampaignButton";
import Pagination from "../components/Pagination";
import AssignEmployeesContext from "../store/assign-employeees-context";
import UpgradePlanToIncludePhishing from "../components/Phishing/UpgradePlanToIncludePhishing";
import { getLoggedInUserDetails } from "../utils/commonUtils";

export default function Phishing() {
  const { isAuthenticated } = useAppContext();
  const assignEmployeesContext = useContext(AssignEmployeesContext);

  const { onImportUsers } = assignEmployeesContext;

  const location = useLocation();
  const history = useHistory();

  const params = queryString.parse(location.search);

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasPhishingAccess, setHasPhishingAccess] = useState(false);
  const [triggerReload, setTriggerReload] = useState(false);
  const [campaignData, setCampaignData] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState(null);
  const [showDeleteCampaignModal, setShowDeleteCampaignModal] = useState(false);
  const [showEditCampaignModal, setShowEditCampaignModal] = useState(false);
  const [showCreatePhishingCampaignModal, setShowCreatePhishingCampaignModal] =
    useState(!!params.create);
  const [currentPage, setCurrentPage] = useState(1);

  const onCreateCampaignButtonClick = () => {
    history.push("/phishing?create=true");
  };

  const onCreateCampaignModalSubmit = async (payload) => {
    try {
      setIsSubmitting(true);
      const campaign = await createPhishingCampaign({
        payload,
        auth: isAuthenticated,
      });
      toast.success("Campaign created successfully!");

      history.push(`/phishing/${campaign.id}`);
    } catch (error) {
      onError(error);
    } finally {
      setShowCreatePhishingCampaignModal(false);
      setIsSubmitting(false);
    }
  };

  const onEditCampaignModalSubmit = async (payload) => {
    try {
      setIsSubmitting(true);
      const updatedCampaign = await editPhishingCampaign({
        payload,
        auth: isAuthenticated,
        campaignId: selectedCampaign.id,
      });

      toast.success("Campaign edited successfully!");

      history.push(`/phishing/${updatedCampaign.id}`);
    } catch (error) {
      onError(error);
    } finally {
      setShowEditCampaignModal(false);
      setIsSubmitting(false);
      setSelectedCampaign(null);
    }
  };

  const onDeleteCampaignModalSubmit = async (campaign) => {
    try {
      setIsSubmitting(true);
      await deletePhishingCampaign({
        campaignId: campaign.id,
        auth: isAuthenticated,
      });
      toast.success("Campaign deleted successfully!");
      setTriggerReload((v) => !v);
    } catch (error) {
      onError(error);
    } finally {
      setShowDeleteCampaignModal(false);
      setIsSubmitting(false);
      setSelectedCampaign(null);
    }
  };

  const onEditCampaignModalClose = () => {
    setShowEditCampaignModal(false);
    setSelectedCampaign(null);
  };

  useEffect(() => {
    const onLoad = async () => {
      try {
        setIsLoading(true);

        const response = await getLoggedInUserDetails({
          auth: isAuthenticated,
        });

        const { has_phishing_access } = response.company_details;

        setHasPhishingAccess(has_phishing_access);

        if (has_phishing_access) {
          const [phishingCampaigns, templates] = await Promise.all([
            getCompanyPhishingCampaigns({
              page: currentPage,
              auth: isAuthenticated,
            }),
            getPhishingTemplates({ auth: isAuthenticated }),
          ]);

          setCampaignData(phishingCampaigns);
          setTemplates(templates.results);
        }
      } catch (error) {
        onError(error);
      }
      setIsLoading(false);
    };

    onLoad();
  }, [isAuthenticated, triggerReload, currentPage]);

  useEffect(() => {
    const params = queryString.parse(location.search);
    setShowCreatePhishingCampaignModal(!!params.create);
  }, [location]);

  useEffect(() => {
    onImportUsers();
  }, [onImportUsers]);

  if (isLoading) {
    return (
      <TransitionWrapper show={isLoading}>
        <Loader fullscreen />
      </TransitionWrapper>
    );
  }

  if (!isLoading && !hasPhishingAccess) {
    return <UpgradePlanToIncludePhishing />;
  }

  return (
    <div>
      <header className="mb-2">
        <div className="flex justify-between mb-1 sm:mb-2">
          <h1>Phishing</h1>
          {isAuthenticated.is_admin && (
            <PhishingCampaignButton
              icon=""
              text="Create Campaign"
              onClick={onCreateCampaignButtonClick}
            />
          )}
        </div>
      </header>
      <main>
        {!campaignData ||
        (campaignData && campaignData.results.length === 0) ? (
          <Callout
            permanent
            title="Haekka"
            message={
              <Fragment>
                <span className="block mb-1">
                  Hey! You haven't created any phishing campaigns yet -- click
                  on the button below to get started!
                </span>
                {isAuthenticated.is_admin && (
                  <PhishingCampaignButton
                    icon="plus"
                    text="Create Campaign"
                    onClick={onCreateCampaignButtonClick}
                  />
                )}
              </Fragment>
            }
            date={false}
          />
        ) : (
          <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-cards grid--campaign-templates">
            {campaignData &&
              campaignData.results.length > 0 &&
              campaignData.results.map((campaign) => (
                <PhishingCampaignCard
                  key={campaign.id}
                  auth={isAuthenticated}
                  phishingCampaign={campaign}
                  setShowDeleteCampaignModal={setShowDeleteCampaignModal}
                  setShowEditCampaignModal={setShowEditCampaignModal}
                  setSelectedCampaign={setSelectedCampaign}
                  setTriggerReload={setTriggerReload}
                  assignEmployeesContext={assignEmployeesContext}
                />
              ))}
          </div>
        )}
        {campaignData && campaignData.count > 0 && (
          <Pagination
            pageSize={8}
            count={campaignData.count}
            currentPage={currentPage}
            onPageChanged={(page) => setCurrentPage(page)}
          />
        )}
      </main>
      {isAuthenticated.is_admin && (
        <Fragment>
          <CreatePhishingCampaignModal
            isSubmitting={isSubmitting}
            showModal={showCreatePhishingCampaignModal}
            templates={templates}
            onCreateCampaign={onCreateCampaignModalSubmit}
            onClose={() => setShowCreatePhishingCampaignModal(false)}
          />
          {selectedCampaign && (
            <DeletePhishingCampaignModal
              isSubmitting={isSubmitting}
              campaign={selectedCampaign}
              showModal={showDeleteCampaignModal}
              onDeletePhishingCampaign={onDeleteCampaignModalSubmit}
              onClose={() => setShowDeleteCampaignModal(false)}
            />
          )}
          {selectedCampaign && (
            <EditPhishingCampaignModal
              isSubmitting={isSubmitting}
              showModal={showEditCampaignModal}
              campaign={selectedCampaign}
              templates={templates}
              onEditCampaign={onEditCampaignModalSubmit}
              onClose={onEditCampaignModalClose}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}
